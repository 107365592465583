import React from 'react';
import { Form } from "react-bootstrap";
import ErrorMessage from '../ErrorMessage';


export default function FormGroup(props) {

  let inputElement = null
  
  switch (props.elementType) {
    case 'input':
      inputElement = (
        <Form.Control {...props.config} type={props.type} defaultValue={props.defaultValue} placeholder={props.placeholder} name={props.name} onChange={props.changed} onBlur={props.blured} disabled={props.disable} isInvalid={!!props.errorMessage} />
      )
      break;

    case 'select':
      inputElement = (
        <Form.Control {...props.config} as="select" placeholder={props.placeholder} type={props.type} name={props.name} onChange={props.changed} isInvalid={!!props.errorMessage}>
          {props.children}
        </Form.Control>
      )
      break;

    default:
      inputElement = (
        <Form.Control {...props.config} type={props.type} placeholder={props.placeholder} name={props.name} disabled={props.disable} onChange={props.changed} isInvalid={!!props.errorMessage} />
      )
      break
  }

  return (
    <Form.Group controlId={props.name} className={props?.className}>
      {props.label && <Form.Label>{props.label}</Form.Label>}
      {inputElement}
      {props.errorMessage && <ErrorMessage isError="1">{props.errorMessage}</ErrorMessage>}
    </Form.Group>
  )

}