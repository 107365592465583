

import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { fieldsForm, schema } from './fields'
import useForm from 'hooks/useForm';
import { REDIRECT_TO_EVENT } from 'schemas/profile';
import { useMutation } from "@apollo/client";
import ErrorMessage from 'components/ErrorMessage';
import useLogin from 'hooks/useLogin';


import './style.scss';
export default function LoginForm() {
    const [loginValues, setLoginValues] = React.useState({
        email: '',
        password: '',
        invitationCode: ''
    });

    const {
        formik,
        renderElementsForm,
        renderElement,
        handleLoadData
    } = useForm({
        fieldsForm,
        schema,
        onSubmit: handleSubmit
    })
    const [message, setMessage] = useState({
        isError: 0,
        text: ""
    });
    const formElements = useMemo(() => renderElementsForm(), [fieldsForm]);
    const [redirectToEvent, { data, error }] = useMutation(REDIRECT_TO_EVENT);
    const { login, loggedIn, status } = useLogin();

    useEffect(() => {
        if (data) {
            window.location.replace(`${WORDPRESS_URL}?p=${data.redirectToEvent?.body.eventId}`)
        }
    }, [data]);

    useEffect(() => {
        setMessage(status);

        if (loggedIn) {
            redirectToEvent({ variables: { fields: { ...loginValues } } });
        }
    }, [loggedIn, status]);

    function handleSubmit(values) {
        values.invitationCode = values.invitationCode.trim();
        setLoginValues(values);
        login(values);
    }


    return (
        <div className="lin-login-form">
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    {message.text && <ErrorMessage isError={message.isError}>{message.text}</ErrorMessage>}
                </Row>
                <Row>
                    <Col className="lin-wrapper">
                        {renderElement(formElements[0])}
                        {renderElement(formElements[1])}
                        {renderElement(formElements[2])}
                    </Col>
                </Row>
                <Button as="input" type="submit" value={"login"} />{' '}
                <a href="/event-reset-password" className="lin-login-form__forgot-password">Forgot my password</a>
            </Form>
        </div >

    )
}