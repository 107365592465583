import * as yup from 'yup';

export const schema = yup.object().shape({
    email: yup.string().email().required("This field is required"),
    password: yup.string().required("This field is required"),
    invitationCode: yup.string().required("This field is required"),
});

export const fieldsForm = {
    email: {
        elementType: 'input',
        controlId: 'registerEmail',
        placeholder: 'Email',

    },
    password: {
        elementType: 'input',
        controlId: 'registerPassword',
        placeholder: 'Password',
        type: 'password'

    },
    invitationCode: {
        elementType: 'input',
        controlId: 'registerInvitationCode',
        placeholder: 'Invitation Code ',
    },
}