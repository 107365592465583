import React, { useState, useEffect } from 'react';
import { GlobalState } from "contexts/GlobalState";
import './style.scss';
import EventButton from '../../components/EventButton';
import { useLazyQuery } from '@apollo/client';
import { CHECK_IF_USER_REGISTER } from 'schemas/profile';


export default function EventPageButton(props) {
    const [globalUser] = GlobalState("user");
    const [checkIfUserRegister, { data, error }] = useLazyQuery(CHECK_IF_USER_REGISTER);
    const [UserRegisterToEvent, setUserRegisterToEvent] = useState(false)
    let text = "";
    let isWatch = false;


    useEffect(() => {
        if (globalUser?.uid)
            checkIfUserRegister({
                variables: { eventId: props.event_id || "128815", uid: globalUser?.uid }
            });

    }, [globalUser]);

    useEffect(() => {
        if (data) {
            setUserRegisterToEvent(data.checkIfUserRegister?.body)
        }
    }, [data]);

    if (!globalUser?.uid || (globalUser?.uid && !UserRegisterToEvent)) {
        text = (props.date === 'now') ? "Register Now" : "Register";
    } else if (UserRegisterToEvent) {
        if (props.date === 'now') {
            text = "Watch Now";
            isWatch = true;
        } else {
            text = "You have successfully registered for the event! <br>Please return on " + props.date;
        }
    }


    return (
        !globalUser?.uid
            ? <EventButton href={props.register_href} text={text} />
            : (
                isWatch ? <a className="watch">{text}</a> : <div className="msg" dangerouslySetInnerHTML={{ __html: text }} />
            )

    )
}
