import React from 'react';
import './style.scss';

function EventButton ({href, text}) {
  return (
    <div className="lin-event-page">
        <a className="event-button" href={href}>{text}</a>
    </div>
  )
}

export default EventButton;
