import React, { useState, createContext, useContext, useEffect } from "react";

import { useQuery } from "@apollo/client";
import { USER } from "../schemas/profile";

// Create Context Object
const GlobalStateContext = createContext();


// Create a provider for components to consume and subscribe to changes
export const GlobalStateProvider = (props) => {

	const [globalState, setGlobalState] = useState(props.store);

	// //user
	const { loading: userLoading, data: userData } = useQuery(USER, {
		fetchPolicy: "no-cache",
	});
	useEffect(() => {
		setGlobalState((prevState) => ({
			...prevState,
			user: { ...userData?.user },
		}));
	}, [userLoading, userData]);
	//


	return (
		<GlobalStateContext.Provider value={[globalState, setGlobalState]}>
			{props.children}
		</GlobalStateContext.Provider>
	);
};

// Get State Data
export const GlobalState = (type) => {
	const [globalState, setGlobalState] = useContext(GlobalStateContext);
	return [
		globalState[type],
		(data) => setGlobalState({ ...globalState, [type]: data })
	];
}

// Get GlobalState as default
export default GlobalState;
