import React from "react";
import ReactDOM from "react-dom";

// Graph
import { ApolloProvider } from "@apollo/client";
import ApolloClient from "services/ApolloClientService";
import { ElementreeProvider, AddComponent } from "contexts/ElementreeProvider"
import { GlobalStateProvider } from "contexts/GlobalState";



const globalStateInitial = {
  user: {}
};

// Setup a general provider with a binding widgets list
ReactDOM.render(
  <ApolloProvider client={ApolloClient.client}>
    <GlobalStateProvider store={globalStateInitial} >
      <ElementreeProvider />
    </GlobalStateProvider>
  </ApolloProvider>,
  document.createElement("div")
);
// ======================== function: ElementreeWidgets ======================== //

!window.widgets && (window.widgets = {});

window.ElementreeWidgets = function (name, el, settings) {
  try {
    if (window.widgets[name]) {
      AddComponent(el, window.widgets[name], settings);
    }
    else {
      el.innerHTML = 'Block ' + "<strong>" + name + "</strong>" + ' is empty client component';
    }
  }
  catch (error) {
    el.innerHTML = 'Client component is broken.' + "<br/>" + error;
  }
}