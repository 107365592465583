

import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import qs from 'query-string';
import { GlobalState } from "contexts/GlobalState";
import ErrorMessage from 'components/ErrorMessage';
import './style.scss';



export default function Verification(props) {
    const loginUrl = "event-login/?t="+ props?.type;
    const [message, setMessage] = useState({
        isError: 0,
        text: ""
    });

    const [globalUser, setGlobalUser] = GlobalState("user");


    useEffect(() => {
        confirm()
    }, []);
    //
    function confirm() {
        const values = qs.parse(window.location.search);
        const uidActive = values["activate"];
        const requestMetadata = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
            body: JSON.stringify({ "uid": uidActive }),
        };
        fetch(`${PROFILE_URL}/auth/confirm`, requestMetadata)
            .then(res => res.json())
            .then(result => {
                if (result?.error) {
                    setMessage({ isError: 1, text: "the user is not found" })
                }
                else if (result) {
                    setGlobalUser({
                        user: { ...result },
                    });

                    console.log(globalUser.user)
                }
            }, error => {
            })

    }

    return (
        <>
            {
                
                !globalUser.user
                    ? (
                        <div className="lin-verification-form">
                            <Row>
                                <p>{message.text}</p>
                            </Row>
                        </div>
                    ) :
                    (
                        <div className="lin-verification-form">
                            <Row>
                                <p>Your registration is complete! Please log in <a href={loginUrl}>here</a></p>
                            </Row>
                        </div >
                    )
            }
        </>

    )
}