
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";

let $instance;

export const getCookie = (name) => {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

class ApolloClientService {
  constructor() {
    this.initClient();
    this.apiUrl = `${GRAPH_URL}/graphql`;
  }
  initClient() {
    const httpLink = new HttpLink({
      uri: this.apiUrl,
      fetchOptions: { redirect: "follow" },
      fetch
    });

    const uploadLink = createUploadLink({ uri: `${GRAPH_URL}/graphql` });
    const cleanTypeName = new ApolloLink((operation, forward) => {
      if (operation.variables && !operation.variables.file) {
        const omitTypename = (key, value) =>
          key === "__typename" ||
            key === "_id" ||
            key === "updatedAt" ||
            key === "createdAt" ||
            key === "lastActivity"
            ? undefined
            : value;
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward(operation).map(data => data);
    });

    const authLink = setContext((_, { headers }) => {
      const token = getCookie("jwt") || localStorage.getItem("token") 
      return {
        headers: {
          ...headers,
          authorization: token ? token : "",
        }
      };
    });

    this.client = new ApolloClient({
      link: ApolloLink.from([cleanTypeName, authLink, uploadLink]),
      cache: new InMemoryCache(),
      shouldBatch: true,
      fetchOptions: {
        credentials: "include"
      }
    });
  }
}
export default $instance || ($instance = new ApolloClientService());
