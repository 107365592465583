import gql from "graphql-tag";


export const REGISTER = gql`
  mutation register($fields: JSON!) {
    register(fields: $fields){
      body
    }
  }
`;


export const LOGIN = gql`
  mutation login($fields: JSON!) {
    login(fields: $fields){
      body
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($fields: JSON!) {
    updateUser(fields: $fields){
      body
    }
  }
`;

export const LOGOUT = gql`
  mutation logout($fields: JSON!) {
    logout(fields: $fields){
      body
    }
  }
`;

export const GET_ENUMS = gql`
  query getEnums{
    getEnums{
      body
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email ){
      body
    }
  }
`;

export const REDIRECT_TO_EVENT = gql`
  mutation redirectToEvent($fields: JSON!) {
    redirectToEvent(fields: $fields){
      body
    }
  }
`;



export const CHECK_IF_USER_REGISTER = gql`
  query checkIfUserRegister($eventId: String! ,$uid: Int!) {
    checkIfUserRegister(eventId: $eventId ,uid: $uid ) {
      body   
  }
}
`;

export const USER = gql`
  query user {
    user {
      uid
      status
      firstName
      lastName
      password
      email
      institute
      invitationCode
      verifyPassword    
      registration {
          uid
          eventId
          invitationCode
          timestamp
          approved
          event {
            id
            url
            background_image
            title
            logo
            event_text
            invitation_code
            max_number_of_codes
          }
      }
  }
}
`;


export const GET_INVITATION_CODE = gql`
  query getInvitationCode($eventId: String!) {
    getInvitationCode(eventId: $eventId) {
      body   
  }
}
`;