import React from 'react';
import { GlobalState } from "contexts/GlobalState";

export default function useLogout() {
  const [globalUser, setGlobalUser] = GlobalState("user");

  const logout = () => {
    const requestMetadata = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include'
    };

    let hasError = 0;

    fetch(`${PROFILE_URL}/auth/logout`, requestMetadata)
      .then(function (res) {
        if (res.status == 400) {
          hasError = 1;
        }

        return res.json();
      })
      .then(result => {
        if (result) {
          if (hasError != 1) {
            localStorage.removeItem("token");  
            location.reload();  
          }
        }
      }, error => {
        console.log('Logout error: ', error);
      })
  }
  return {
    logout
  }
}